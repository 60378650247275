<template>
  <div class="home">
    <Environment v-if="environment != 'production'"/>
    <Header class="contactheader"/>
    <div class="mainindexcontentcontainer " style="padding-bottom:100px;">   
    <div class="container" style="text-align:left;">
      <div class="col-xs-12 col-sm-8 ">
        <h1>Contact Information</h1>
        <p><b>Phone:</b> 902-367-3901</p>
        <p><b>Email:</b><a style="color:#705e51" href="mailto:jacinta@jacintagallant.ca"> jacinta@jacintagallant.ca</a></p>
        <p><b>Location :</b> 89 Hillsborough Street, Charlottetown, PE C1A 4W5</p><br/>
        <router-link to="TrainingPresentations" class="gotobutton" style="width:100%; font-size:1.1em;" >For Training Inquiries Click Here!</router-link>
        </div>
      
        <div class="col-xs-12 col-sm-4 ">
          <h1>Suggested Links</h1><br>  
          <a style="font-size:1em;" href="https://www.innovateforlawyers.com/" target="_blank">Innovation For Lawyers</a><br><br>
          <a style=" font-size:1em;" href="https://www.intwohomes.com/" target="_blank">Our Family In Two Homes</a><br><br>
          <a style=" font-size:1em;" href="https://www.innovateforlawyers.com/#podcast" target="_blank">The Authentic Professional Podcast</a><br><br>
          <a style=" font-size:1em;" href="https://www.collaborativepractice.com/" target="_blank">International Academy of <br/>Collaborative Professionals</a><br><br>        
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      environment
    }
  }
}
</script>

<style lang="scss">
  
</style>